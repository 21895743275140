import "bootstrap/dist/css/bootstrap.min.css";
import propTypes from "prop-types";
import React from "react";
import { Col, ListGroup, ListGroupItem, Row } from "shards-react";
import "shards-ui/dist/css/shards.min.css";

const ListBlock = ({ title, items }) => {
  return (
    <Row className={`gutter-top list-block `}>
      <Row>
        <h3>{title}</h3>
      </Row>
      <Row className={"gutter-top"}>
        <Col lg="2" md="0" sm="0"></Col>
        <Col lg="8" md="12" sm="12">
          <ListGroup>
            {items.map(item => (
              <ListGroupItem>{item}</ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col lg="2" md="0" sm="0" />
      </Row>
    </Row>
  );
};

ListBlock.propTypes = {
  variant: propTypes.oneOf(["left", "right"])
};

export default ListBlock;
