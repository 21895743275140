import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { PageContext } from "../App";
import Navigation from "./Navigation";
import SmallHeader from "./SmallHeader";

const AlertModal = () => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <Modal open={isOpen} toggle={setIsOpen}>
      <ModalBody><b>Z důvodu čerpání mateřské dovolené momentálně nepřijímám termíny k objednání ani nové klienty. </b>
      <br/><br/>Děkuji za pochopení, <br/> Leona Fišmanová</ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)} size="sm" active theme="success">
          Rozumím
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(AlertModal);
