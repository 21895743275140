import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "shards-ui/dist/css/shards.min.css";
import styled from "styled-components";
import "./App.scss";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import SmallHeader from "./Components/SmallHeader";
import WebRouter from "./router";
import SimpleReactLightbox from "simple-react-lightbox";

export const PageContext = React.createContext({ title: "" });
export const PAGE_TITLE = "Kosmetika Leona, Kyjov -";
export const PAGE_DESCRIPTION =
  "Kosmetika Leona je kosmetický salon v Kyjově, nabízející služby z oblasti kosmetiky, úpravy a stylingu řas a masážemi. V tomto kyjovském salonu naleznete odpočinek a relaxační atmosféru, především ale kvalitní kosmetickou péči.";
export const PAGE_AUTHOR = "Jiří Dostál - jiridostal@hotmail.com";
export const PAGE_KEYWORDS =
  "kosmetika, kyjov, kosmeticky, salon, leona, fismanova, masaze, osetreni, poukazy, rasy, oboci, depilace";
const App = ({ location }) => {
  const [activeTab, setActiveTab] = React.useState();
  const [title, setTitle] = React.useState("osalonu");
  return (
    <PageContext.Provider value={{ activeTab, setActiveTab, title, setTitle }}>
      <SimpleReactLightbox>
        <Header />
        <Wrapper className={"main-container"}>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames={"fade"}
              timeout={{ enter: 300, exit: 300 }}
            >
              <section className="route-section">
                <WebRouter location={location} />
                <Footer />
              </section>
            </CSSTransition>
          </TransitionGroup>
        </Wrapper>
      </SimpleReactLightbox>
    </PageContext.Provider>
  );
};

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
  section.route-section {
    position: absolute;
    width: 100%;
    padding-bottom: 200px;
    top: 0;
    left: 0;
  }
`;

export default withRouter(App);
