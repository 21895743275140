import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const CenikKosmetickaPece = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("Ceník - Kosmetická péče");
    setActiveTab("cenik");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Ceník kosmetické péče`}</title>
        <meta
          name="description"
          content={
            "Aktuální ceníky kosmetického salonu Leona pro uvedené služby"
          }
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>

      <PageBreadcrumb />
      <Container>
        <ContentBlock variant="center" title="Ceník - Kosmetická péče">
          <div style={{ textAlign: "center" }}>Ceny platné od 1.3.2023</div>
          <br />
          <br />
          <h5>Kosmetická ošetření</h5>V ceně ošetření je zdarma úprava obočí,
          barvení řas a ošetření ultrazvukovou špachtlí.
          <br />
          <br />
          <table className={"table table-striped "}>
            <thead>
              <tr>
                <th>Služba</th>
                <th>Délka</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Kosmetická péče kompletní (včetně masáže obličeje a dekoltu)
                </td>
                <td>90 minut</td>
                <td>950 Kč</td>
              </tr>
              <tr>
                <td>Kosmetická péče základní</td>
                <td>60 minut</td>
                <td>800 Kč</td>
              </tr>
              <tr>
                <td>Masáž obličeje a dekoltu - samostatně</td>
                <td>30 minut</td>
                <td>500 Kč</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h5>Jednotlivé úkony</h5>
          <table className={"table table-striped "}>
            <thead>
              <tr>
                <th>Služba</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Úprava obočí</td>
                <td>150 Kč</td>
              </tr>
              <tr>
                <td>Úprava obočí s barvením</td>
                <td>220 Kč</td>
              </tr>
              <tr>
                <td>Úprava s barvením obočí a barvení řas</td>
                <td>280 Kč</td>
              </tr>
              <tr>
                <td>Barvení řas</td>
                <td>150 Kč</td>
              </tr>
              <tr>
                <td>Barvení obočí</td>
                <td>150 Kč</td>
              </tr>
            </tbody>
          </table>
          Jednotlivé úkony jsou zdarma zahrnuty v balíčku kosmetického ošetření
          <br />
          <br />
          <h5>Depilace</h5>
          <table className={"table table-striped "}>
            <thead>
              <tr>
                <th>Služba</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Horní ret</td>
                <td>75 Kč</td>
              </tr>
              <tr>
                <td>Brada</td>
                <td>75 Kč</td>
              </tr>
              <tr>
                <td>Ruce</td>
                <td>220 Kč</td>
              </tr>
              <tr>
                <td>Lýtka</td>
                <td>300 Kč</td>
              </tr>
              <tr>
                <td>Celé nohy</td>
                <td>500 Kč</td>
              </tr>
              <tr>
                <td>Třísla</td>
                <td>180 Kč</td>
              </tr>
              <tr>
                <td>Podpaží</td>
                <td>150 Kč</td>
              </tr>
              <tr>
                <td>Záda</td>
                <td>500 Kč</td>
              </tr>
              <tr>
                <td>Břicho</td>
                <td>500 Kč</td>
              </tr>
            </tbody>
          </table>
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default CenikKosmetickaPece;
