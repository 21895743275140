import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import "shards-ui/dist/css/shards.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { PageContext } from "../App";
import AlertModal from "./AlertModal";
import Navigation from "./Navigation";
import SmallHeader from "./SmallHeader";

const Header = ({ location }) => {
  const [sliderRef, setSliderRef] = React.useState();
  const { title } = React.useContext(PageContext);
  React.useEffect(() => sliderRef && sliderRef.slickNext(), [location]);
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
  };
  return (
    <div className={"header-image-container"}>
      <Navigation />
      <Slider ref={(c) => setSliderRef(c)} {...settings}>
        <div>
          <img src="header.jpg" />
        </div>
        <div>
          <img src="header-2.jpg" />
        </div>
        <div>
          <img src="onas-header.jpg" />
        </div>
      </Slider>
      <div className={"header-content"}>
        <h1>
          Kosmetika <br />
          <b>Leona</b>
        </h1>
        <hr />

        <h3>{title}</h3>
      </div>
      <AlertModal/>
    </div>
  );
};
/*

        */
export default withRouter(Header);
