import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { FaAt, FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import { Tooltip as ShardsTooltip } from "shards-react";
import "shards-ui/dist/css/shards.min.css";

const SocialBar = ({ mobile }) => {
  const [fbOpen, setFbOpen] = React.useState(false);
  const [igOpen, setIgOpen] = React.useState(false);
  const [phoneOpen, setPhoneOpen] = React.useState(false);
  const [mailOpen, setMailOpen] = React.useState(false);
  if (mobile) {
    return (
      <div className={"social-bar"}>
        <a href="https://www.facebook.com/kosmetikaleona/" target="__blank">
          <FaFacebookF />
        </a>
        <a href="https://www.instagram.com/kosmetikaleona/" target="__blank">
          <FaInstagram />
        </a>
        <a href="tel:+420 774 644 757">
          <FaPhone />
        </a>
        <a href="mailto:leona.fismanova@seznam.cz">
          <FaAt />
        </a>
      </div>
    );
  } else {
    return (
      <div className={"social-bar"}>
        <React.Fragment>
          <ShardsTooltip
            open={fbOpen}
            target={`#fbTooltip`}
            toggle={() => setFbOpen(!fbOpen)}
          >
            {"Facebook"}
          </ShardsTooltip>

          <ShardsTooltip
            open={igOpen}
            target={`#igTooltip`}
            toggle={() => setIgOpen(!igOpen)}
          >
            {"Instagram"}
          </ShardsTooltip>
          <ShardsTooltip
            open={phoneOpen}
            target={`#phoneTooltip`}
            toggle={() => setPhoneOpen(!phoneOpen)}
          >
            {"+420 774 644 757"}
          </ShardsTooltip>
          <ShardsTooltip
            open={mailOpen}
            target={`#mailTooltip`}
            toggle={() => setMailOpen(!mailOpen)}
          >
            {"leona.fismanova@seznam.cz"}
          </ShardsTooltip>
          <a href="https://www.facebook.com/kosmetikaleona/" target="__blank">
            <FaFacebookF id="fbTooltip" />
          </a>
          <a href="https://www.instagram.com/kosmetikaleona/" target="__blank">
            <FaInstagram id="igTooltip" />
          </a>
          <FaPhone id="phoneTooltip" />

          <FaAt id="mailTooltip" />
        </React.Fragment>
      </div>
    );
  }
};

export default SocialBar;
