import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { FaAt, FaMapMarked, FaPhone, FaRegFrown } from "react-icons/fa";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const NotFound = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);

  React.useEffect(() => {
    setTitle("");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} 404`}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <Container>
        <ContentBlock
          variant="center"
          title="Chyba 404 - požadovaná stránka neexistuje"
        >
          <p style={{ textAlign: "center", margin: "auto" }}>
            <FaRegFrown size="150px" color="black" />
          </p>
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default NotFound;
