import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const SluzbySpachtleEtani = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("Ultrazvuková špachtle Etani");
    setActiveTab("sluzby");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Ultrazvuková špachtle Etani`}</title>
        <meta
          name="description"
          content={
            "Popis služby Ultrazvuková špachtle Etani prováděné v kosmetickém salonu Kosmetika Leona"
          }
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb />
      <Container>
        <ContentBlock
          variant="right"
          title="Proč Ultrazvuková špachtle Etani?"
          image={"spachtle1.jpg"}
        >
          Ultrazvuková špachtle patří k nejoblíbenějším pomocníkům kosmetiček.
          Účinnost a rychlost, s jakou čistí pleť a aktivuje v ní cenné látky z
          kosmetických sér a koncentrátů, je nevídaná.
          <br />
          <br />
          <ul>
            <li>
              Hloubkově čistí pleť a zažehluje do ní účinné látky z kosmetiky
            </li>
            <li>Omlazuje a zpevňuje pleť drobnými vibracemi</li>

            <li>Vyhlazuje vrásky a zpomaluje stárnutí</li>

            <li>
              Odstraňuje až 80 % nečistot bez vytlačování a podráždění pleti
            </li>

            <li>Potlačuje akné a pomáhá mu předcházet</li>

            <li>Zmírňuje nežádoucí pigmentace a jizvičky</li>

            <li>Prokrvuje, hydratuje a celkově ozdravuje pleť</li>

            <li>Odstraňuje otoky</li>
          </ul>
        </ContentBlock>
        <ContentBlock variant="center" title="">
          <h5>Jak ultrazvuková špachtle funguje?</h5>
          Ultrazvuková špachtle funguje na principu velice jemných
          vysokofrekvenčních vibrací, které jsou generované s rychlostí 25 000
          pulsů za vteřinu. Ty odvádějí nečistoty na povrch pokožky a pryč z ní
          a zároveň umožňují kosmetickým sérům propracovat se k hlubším vrstvám
          pokožky.
          <br />
          <br />
          <h5>Pro koho je ultrazvuková špachtle určena?</h5>
          Ultrazvuková špachtle je velmi šetrná, takže je vhodná pro všechny
          typy pleti, včetně té nejcitlivější.
          <ul>
            <li>
              U <b>stárnoucí a suché pleti</b> vyhlazuje ultrazvuková špachtle
              povrch kůže a odstraňuje jemné vrásky
            </li>
            <li>
              U <b>mastné a aknózní pleti</b> čistí póry a brání hromadění
              keratinu v ústí mazových žláz, čímž předchází vzniku dalších
              pupínků.
            </li>
          </ul>
          <h5>Výsledný efekt</h5>
          Výsledkem ošetření Etani ultrazvukovou špachtlí je čistá, hydratovaná,
          rozjasněná, dobře prokrvená a zdravá pleť mladšího vzhledu.
          <br />
          <br />
          <h5>Pravidla a kontraindikace použití</h5>
          <ul>
            <li>
              Pro ošetření musí být klientka zdravá a nesmí mít ekzémy nebo jiné
              alergické stavy v místě aplikace
            </li>
            <li>
              Přístroj nesmí používat lidé s onemocněním štítné žlázy, srdce, s
              onkologickým onemocněním, těhotné ženy, lidé s epilepsií a lidé s
              kardiostimulátorem
            </li>
          </ul>
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default SluzbySpachtleEtani;
