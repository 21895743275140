import React from "react";
import { Route, Switch } from "react-router-dom";
import CenikKosmetickaPece from "./Pages/CenikKosmetickaPece";
import CenikMasaze from "./Pages/CenikMasaze";
import CenikUpravaRas from "./Pages/CenikUpravaRas";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import KosmetickaPece from "./Pages/KosmetickaPece";
import LandingPageContent from "./Pages/LandingPageContent";
import SluzbyMasaze from "./Pages/SluzbyMasaze";
import SluzbySpachtleEtani from "./Pages/SluzbySpachtleEtani";
import SluzbyUpravaRas from "./Pages/SluzbyUpravaRas";
import NotFound from "./Pages/NotFound";

const WebRouter = ({ location }) => (
  <Switch location={location}>
    <Route path="/" exact component={LandingPageContent} />
    <Route path="/kontakt" exact component={Contact} />
    <Route path="/fotogalerie" exact component={Gallery} />
    <Route path="/uprava-a-styling-ras" exact component={SluzbyUpravaRas} />
    <Route
      path="/ultrazvukova-spachtle-etani"
      exact
      component={SluzbySpachtleEtani}
    />
    <Route path="/kosmeticka-pece" exact component={KosmetickaPece} />

    <Route
      path="/cenik-uprava-a-styling-ras"
      exact
      component={CenikUpravaRas}
    />
    <Route
      path="/cenik-kosmeticka-pece"
      exact
      component={CenikKosmetickaPece}
    />
    <Route component={NotFound} />
  </Switch>
);

export default WebRouter;
