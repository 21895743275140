import "bootstrap/dist/css/bootstrap.min.css";
import propTypes from "prop-types";
import React from "react";
import { FaHeart } from "react-icons/fa";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";

const Footer = () => {
  return (
    <footer>
      <Container>
        <div>
          <img src="./logo_full.png" />
        </div>
        <div>
          <h5>
            Kosmetika <b>Leona</b>
          </h5>
          Nerudova 243, Kyjov
        </div>
        <div>
          <div className={"footer-author"}>
            created with <FaHeart />
            <a
              href="https://www.linkedin.com/in/ji%C5%99%C3%AD-dost%C3%A1l-87786173/"
              target="__blank"
            >
              Jiří Dostál
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  variant: propTypes.oneOf(["left", "right"]),
};

export default Footer;
