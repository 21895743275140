import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { FaAt, FaMapMarked, FaPhone } from "react-icons/fa";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const Contact = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);

  React.useEffect(() => {
    setTitle("Kontakt");
    setActiveTab("kontakt");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Kontakt`}</title>
        <meta
          name="description"
          content={"Kontaktní informace na Kosmetika Leona, včetně polohy."}
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb items={[{ title: "Kontakt", link: "/kontakt" }]} />
      <Container>
        <ContentBlock variant="center" title="Otevírací doba">
          <p style={{ textAlign: "center" }}>
            Otevírací doba od po-pá dle objednávek. Sobotní a sváteční provoz je
            na domluvě a s příplatkem 20% k ceníkové ceně.
          </p>
        </ContentBlock>
        <ContentBlock title="Kontakt" image={"contact.jpg"} variant={"left"}>
          <h5>Leona Fišmanová</h5>
          <dl>
            <dt>
              <FaMapMarked /> Adresa
            </dt>
            <dd>
              Nerudova 243
              <br />
              Kyjov, 697 01
            </dd>
            <dt>
              <FaPhone /> Telefon
            </dt>
            <dd>+420 774 644 757</dd>
            <dt>
              <FaAt /> E-Mail
            </dt>
            <dd>leona.fismanova@seznam.cz</dd>
            <dt>IČO</dt>
            <dd>88059936</dd>
          </dl>
        </ContentBlock>
        <ContentBlock title="Kde nás najdete" variant={"center"}>
          <div class="mapouter">
            <div style={{ textAlign: "center" }}>
              <iframe
                width="600"
                height="500"
                src="https://maps.google.com/maps?q=Nerudova+243%2F29,+697+01+Kyjov&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default Contact;
