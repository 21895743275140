import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "shards-ui/dist/css/shards.min.css";
import { Container, Row, Col, ListGroup, ListGroupItem } from "shards-react";
import ContentBlock from "../Components/ContentBlock";
import ListBlock from "../Components/ListBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";
import WelcomeHeader from "../Components/WelcomeHeader";
import {
  PageContext,
  PAGE_TITLE,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_AUTHOR
} from "../App";
import { Button } from "shards-react";
import { Helmet } from "react-helmet";

const LandingPageContent = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("O salonu");
    setActiveTab("osalonu");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} O salonu`}</title>
        <meta name="description" content={PAGE_DESCRIPTION} />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb items={[{ title: "O salonu", link: "/" }]} />
      <Container>
        <WelcomeHeader />
        <ContentBlock title="O salonu" image={"landing.jpg"} variant={"left"}>
          <p>
            V salonu Kosmetika Leona Vám nabízím kvalitní a efektivní kosmetické
            ošetření italskou kosmetikou ALISSA BEAUTÉ.
            <br />
            <br />
          </p>
          <p>
            Salon se nachází v dosahu 2 minut pěší chůze od autobusového nádraží
            ve středu Kyjova. Prostory salonu jsou klimatizované, proto i v
            horkých letních dnech bude Váš relax ničím nerušený.
            <br />
            <br />
          </p>
          <p>
            Neváhejte a svůj termín si rezervujte ještě dnes. Budu se těšit na
            Vaši návštěvu!
          </p>
        </ContentBlock>
        <ListBlock
          title={"Ráda Vám nabídnu tyto služby"}
          items={[
            "Ošetření pleti a její hloubkové čištění",
            "Masáže obličeje, krku a dekoltu, mikromasáž očního okolí",
            "Ošetření ultrazvukovou špachtlí Etani",
            "Barvení řas a obočí, úpravu obočí Lash- lifting",
            "Prodlužování řas",
            "Vosková depilace těla",
            <b>Zakoupení celoročních i vánočních dárkových poukazů</b>,
            "Doporučení a prodej kosmetiky pro Vaši domácí péči"
          ]}
        />
      </Container>
    </React.Fragment>
  );
};

export default LandingPageContent;
