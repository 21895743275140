import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import "shards-ui/dist/css/shards.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { PageContext } from "../App";
import Navigation from "./Navigation";

const SmallHeader = ({ title }) => {
  return (
    <div className={"header-image-container"}>
      <Navigation />
      <div className={"header-content-small"}>
        <div className="container" style={{ padding: 0 }}>
          <div className={"header-content-small-box"}>
            <div className={"header-content-small-content"}>
              <h1>
                Kosmetika <br />
                Leona
              </h1>
              <hr />
              <h3>{title}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/*

        */
export default withRouter(SmallHeader);
