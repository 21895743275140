import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";
import { galleryFiles } from "../galleryFiles";
import { SRLWrapper } from "simple-react-lightbox";

const Gallery = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("Fotogalerie");
    setActiveTab("fotogalerie");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Fotogalerie`}</title>
        <meta
          name="description"
          content={"Fotogalerie prostorů a ukázek práce Kosmetiky Leona"}
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb
        items={[{ title: "Fotogalerie", link: "/fotogalerie" }]}
      />
      <Container>
        <ContentBlock title="Fotogalerie" variant={"center"}>
          <SRLWrapper>
            <div className={"gallery"}>
              {galleryFiles.map((file) => (
                <a href={file.img} data-attribute="SRL">
                  <img src={file.thumb} />
                </a>
              ))}
            </div>
          </SRLWrapper>
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default Gallery;
