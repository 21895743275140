import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const KosmetickaPece = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("Kosmetická péče");
    setActiveTab("sluzby");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Kosmetická péče`}</title>
        <meta
          name="description"
          content={
            "Shrnutí kosmetických služeb nabízených salonem Kosmetika Leona"
          }
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb
        items={[{ title: "Kosmetická péče", link: "/kosmeticka-pece" }]}
      />
      <Container>
        <ContentBlock
          variant="right"
          title="Kosmetické ošetření a čištění pleti Alissa Beauté"
          image={"kosmeticka-pece.jpg"}
        >
          Zlepšuje stav pleti díky uvolnění uzavřených komedonů. U mastných typů
          pletí šetrně potlačí činnost mazových žláz. Zabezpečuje její
          regeneraci a optimální hladinu vlhkosti v závislosti na ročním období
          a potřebách pokožky. Pomáhá pokožce bojovat proti stresům způsobených
          klimatem (mráz,slunce,vítr) a zbavovat se nečistot ze znečištěného
          ovzduší. Tím zpomaluje proces stárnutí. Pravidelnou a důslednou péčí
          se Vám pleť odvděčí pružností a mladitvějším vzhledem i ve vyšším
          věku. Každá pleť je specifická, proto kladu důraz na individuálnost
          ošetření.
        </ContentBlock>
        <ContentBlock
          variant="left"
          title="Barvení a formování obočí, barvení řas"
          image={"oboci.jpg"}
        >
          Oči a obočí jsou nejdůležitější částí obličeje a zanechávají v člověku
          silný první dojem. Velice důležitou roli hraje i úprava obočí, jež
          ovlivňuje celkový výraz obličeje. Samozřejmostí je perfektní tvar,
          barva a formování obočí, díky kterému obličej působí otevřeně a oči
          živě. K barvení řas používám nejčastěji barvu černou. <br />K barvení
          obočí světle hnědou, tmavě hnědou, mahagonovou,u černovlásek černou.
          Pro barvu na míru k Vašemu typu jednotlivé barvy i míchám.
        </ContentBlock>
        <ContentBlock
          variant="right"
          title="Masáž obličeje a dekoltu"
          image={"masaze5.jpg"}
        >
          Je ruční kosmetická masáž, a která je jednou z nejúčinnějších
          prostředků v péči o pleť. Masáž působí na kůži, svaly, kolegenni a
          elastické vlákna v ní, ale i na psychiku a nervovou soustavu. Je
          prevencí proti stárnutí a zlepšení stavu pleti.
        </ContentBlock>
        <ContentBlock
          variant="left"
          title="Mikromasáž očního okolí"
          image={"masaze4.jpg"}
        >
          Pokožka v okolí očí začíná stárnout jako první, již kolem 25. roku,
          ale mnohdy i dříve. Zde se nejvíce projevuje únava, stres a škodlivé
          vnější vlivy. <br />
          Díky mikromasáži očního okolí se snižuje prohloubení vrásek okolo očí,
          zmenšují se váčky a kruhy pod očima.
          <br />
          Mikromasáž očního okolí napomáhá vyplavování toxinů, podporuje obnovu
          buněk, zabraňuje ztrátě vlhkosti a pružnosti pokožky.
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default KosmetickaPece;
