import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Col, Row } from "shards-react";
import "shards-ui/dist/css/shards.min.css";

const WelcomeHeader = () => {
  return (
    <Row className="split gutter-top landing-box">
      <Col lg="8" md="8" sm="8" className={""}>
        <p className="content-narrow lead responsive-text">
          Vítejte v salónu Kosmetika Leona, který bude právě i pro Vás znamenat
          příjemné prostředí, ve kterém zapomenete na každodenní starosti.
        </p>
      </Col>
      <blockquote className="gutter-top">
        Mezi priority mého salonu patří nejen odpočinek a relaxační atmosféra,
        především ale kvalitní kosmetická péče...
        <span>Leona Fišmanová</span>
      </blockquote>
    </Row>
  );
};

export default WelcomeHeader;
