import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { FaBars } from "react-icons/fa";
import { Link, withRouter } from "react-router-dom";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext } from "../App";
import SocialBar from "./SocialBar";

const Navigation = ({ location }) => {
  const [sluzbyOpen, toggleSluzby] = React.useState();
  const [cenikyOpen, toggleCeniky] = React.useState();
  const [collapseOpen, toggleNavbar] = React.useState();
  const { activeTab } = React.useContext(PageContext);
  React.useEffect(() => toggleNavbar(false), [location]);
  return (
    <React.Fragment>
      <Navbar expand="md">
        <div className={"container"}>
          <div className={"mobile-nav"}>
            <FaBars onClick={() => toggleNavbar(!collapseOpen)} size="20px" />
            <span>
              <h6>Kosmetika Leona</h6>
            </span>
            <SocialBar mobile />
          </div>
          <Collapse open={collapseOpen} navbar>
            <Nav fill>
              <div className={"navbar-group"}>
                <div className="full-logo">
                  <img src="./logo_full.png" />
                  <span>
                    <h6>
                      Kosmetika <b>Leona</b>
                    </h6>
                  </span>
                </div>
              </div>
              <div className={"navbar-group"}>
                <NavItem>
                  <Link to="/">
                    <NavLink
                      href="javascript:;"
                      className={activeTab === "osalonu" && "active"}
                    >
                      O salonu
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Dropdown
                    open={sluzbyOpen}
                    toggle={() => toggleSluzby(!sluzbyOpen)}
                  >
                    <DropdownToggle
                      nav
                      caret
                      className={activeTab === "sluzby" && "active"}
                    >
                      Služby
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link to="/kosmeticka-pece">
                        <DropdownItem>Kosmetická péče</DropdownItem>
                      </Link>
                      <Link to="/uprava-a-styling-ras">
                        <DropdownItem>Úprava a styling řas</DropdownItem>
                      </Link>
                      <Link to="/ultrazvukova-spachtle-etani">
                        <DropdownItem>Ultrazvuková špachtle Etani</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                <NavItem>
                  <Dropdown
                    open={cenikyOpen}
                    toggle={() => toggleCeniky(!cenikyOpen)}
                  >
                    <DropdownToggle
                      nav
                      caret
                      className={activeTab === "cenik" && "active"}
                    >
                      Ceníky
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link to="/cenik-kosmeticka-pece">
                        <DropdownItem>Kosmetická péče</DropdownItem>
                      </Link>
                      <Link to="/cenik-uprava-a-styling-ras">
                        <DropdownItem>Úprava a styling řas</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>

                <NavItem>
                  <Link to="/fotogalerie">
                    <NavLink
                      className={activeTab === "fotogalerie" && "active"}
                      href="javascript:;"
                    >
                      Fotogalerie
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/kontakt">
                    <NavLink
                      href="javascript:;"
                      className={activeTab === "kontakt" && "active"}
                    >
                      Kontakt
                    </NavLink>
                  </Link>
                </NavItem>
              </div>
              <div className={"navbar-group"}>
                <NavItem>
                  <SocialBar />
                </NavItem>
              </div>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default withRouter(Navigation);
