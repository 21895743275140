import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const SluzbyUpravaRas = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("Úprava a styling řas");
    setActiveTab("sluzby");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Úprava a styling řas`}</title>
        <meta
          name="description"
          content={"Popis služeb z oblasti úpravy a stylingu řas"}
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb />
      <Container>
        <ContentBlock
          variant="right"
          title="Lash Lifting"
          image={"uprava-ras1.jpg"}
        >
          Novinka v oblasti tvarování a vyplňování vlastních řas. Tato technika
          tzv. LASH LIFTING spočívá v napnutí a zatočení řas na speciální
          silikonovou formu. Výsledným efektem jsou zatočené a jakoby vytažené
          řasy směrem k obočí. Následně se aplikuje barva na řasy, což je na
          řase opravdu vidět. <br />
          LASH LIFTING je proces k zatočení, zvýraznění a vede k omlazení oka a
          vyživení Vašich řas. Lash lifting je hypoalergenní. <br />
          Výdrž na oku je cca 6-8 týdnů
        </ContentBlock>
        <ContentBlock
          variant="left"
          title="Prodlužování řas"
          image={"uprava-ras2.jpg"}
        >
          V salonu Kosmetika Leona provádím mimo kosmetické zákroky, také službu
          prodloužení řas. Pokud jste pro přirozenost, nebo chcete mít oči jako
          mrkací panenka…jsem tu pro Vás! Pracuji s nejkvalitnějším a
          nejbezpečnějším materiálem dostupným na trhu. Pokud s řasy ještě
          nemáte zkušenosti, nemáte se čeho bát! Vše Vám vysvětlím a následně
          Vám dám ještě letáček s údaji o následné péči. Vždy s Vámi pohovořím o
          všem, co je pro Vás vhodné a zvolím tu nejlepší variantu, vzhledem k
          Vašim požadavkům.
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default SluzbyUpravaRas;
