import "bootstrap/dist/css/bootstrap.min.css";
import propTypes from "prop-types";
import React from "react";
import { Col, Row } from "shards-react";
import "shards-ui/dist/css/shards.min.css";

const ContentBlock = ({ variant, children, title, image }) => {
  switch (variant) {
    case "left":
    case "right":
      return (
        <Row className={`split gutter-top content-block-${variant}`}>
          <h3>{title}</h3>

          <Row className={"gutter-top"}>
            <Col lg="4" md="12" sm="12">
              <img src={image}></img>
            </Col>
            <Col lg="8" md="12" sm="12" className={"responsive-text"}>
              {children}
            </Col>
          </Row>
        </Row>
      );

    case "center":
      return (
        <Row className={`gutter-top content-block-${variant}`}>
          <h3>{title}</h3>

          <Row className={"gutter-top"}>
            <Col lg="12" md="12" sm="12" className={"responsive-text"}>
              {children}
            </Col>
          </Row>
        </Row>
      );
    default:
      break;
  }
};

ContentBlock.propTypes = {
  variant: propTypes.oneOf(["left", "right"])
};

export default ContentBlock;
