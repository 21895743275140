import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "shards-react";
import "shards-ui/dist/css/shards.min.css";
import { PageContext, PAGE_AUTHOR, PAGE_KEYWORDS, PAGE_TITLE } from "../App";
import ContentBlock from "../Components/ContentBlock";
import PageBreadcrumb from "../Components/PageBreadcrumb";

const CenikUpravaRas = () => {
  const { setActiveTab, setTitle } = React.useContext(PageContext);
  React.useEffect(() => {
    setTitle("Ceník - Úprava a styling řas");
    setActiveTab("cenik");
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${PAGE_TITLE} Ceník úpravy a stylingu řas`}</title>
        <meta
          name="description"
          content={
            "Aktuální ceníky kosmetického salonu Leona pro uvedené služby"
          }
        />
        <meta name="keywords" content={PAGE_KEYWORDS} />
        <meta name="author" content={PAGE_AUTHOR} />
      </Helmet>
      <PageBreadcrumb />
      <Container>
        <ContentBlock variant="center" title="Ceník - Úprava a styling řas">
          <table className={"table table-striped "}>
            <thead>
              <tr>
                <th>Služba</th>
                <th>Délka</th>
                <th>Cena</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Prodlužování řas metodou řasa-řasu</td>
                <td>90 - 120 minut</td>
                <td>1300 Kč</td>
              </tr>
              <tr>
                <td>Prodlužování řas metodou řasa-řasu - doplnění</td>
                <td>60 - 90 minut</td>
                <td>660 Kč</td>
              </tr>
              <tr>
                <td>
                  Prodlužování řas - doplnění řas aplikovaných u jiných
                  stylistek
                </td>
                <td>60 - 90 minut</td>
                <td>850 Kč</td>
              </tr>
              <tr>
                <td>Lash lifting (natočení přírodních řas s barvením)</td>
                <td>60 minut</td>
                <td>760 Kč</td>
              </tr>
            </tbody>
          </table>
        </ContentBlock>
      </Container>
    </React.Fragment>
  );
};

export default CenikUpravaRas;
