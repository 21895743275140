import "bootstrap/dist/css/bootstrap.min.css";
import propTypes from "prop-types";
import React from "react";
import { Breadcrumb } from "shards-react";
import "shards-ui/dist/css/shards.min.css";

const PageBreadcrumb = ({ items }) => {
  return (
    <Breadcrumb>
      <img src="logo.png"></img>
    </Breadcrumb>
  );
};

PageBreadcrumb.propTypes = {
  items: propTypes.array
};

export default PageBreadcrumb;
